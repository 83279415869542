import './FAQ.scss';

import React from 'react';

import { FAQItem } from './FAQItem/FAQItem';

export const FAQ = () => (
  <div className="row d-flex justify-content-center position-relative my-5">
    <div id="faqs" className="anchor"></div>
    <h2>FAQs</h2>
    <h3 className="h4 mt-2">The most common questions answered</h3>
    <div className="d-flex flex-column faq-container">
      <FAQItem
        question="What services does our event tech company offer?"
        answer={`We provide a seamless digital product that compliments your brand and looks beautiful in your visitor's hands via our bespoke mobile app. Behind the scenes is a robust CMS (admin panel) for you to serve the content, manage the setlist and see analytics of festival visitors.

If you already have a platform and are ready to join the VIKIN ship instead, we can help you migrate over easily.

If you are just getting started in the events and festival industry and don’t have any branding or presence, reach out to us, and we can provide you with a full suite of digital brand services tailored to your business intentions.

`}
      />
      <FAQItem
        question="I’m already with another provider; why swap?"
        answer={`That's amazing! You know how VIKIN can elevate your app and improve your visitor experience. We can help you migrate your data into our platform. Join the VIKIN family.`}
      />
      <FAQItem
        question="Why VIKIN? What makes your service better?"
        answer={`Simply put, we extend your brand into the palm of your visitor's hand, a bespoke creation. We provide an intuitive and comprehensive app combined with your content and brand. We also seamlessly blend your partners (vendors and sponsors) into the digital experience, giving them the love they deserve but not impacting the experience for all festival goers.`}
      />
      <FAQItem
        question="Do you support partnerships (sponsors & vendors)?"
        answer="Of course, they are a massive part of the experience you provide. We effortlessly integrate your partners (vendors and sponsors) into the digital experience, providing them with the appreciation they deserve without affecting the overall festival experience for all attendees."
      />
    </div>
  </div>
);
