import React from 'react';

import { ImageExtension } from '../../models/image';

interface Props {
  img: string;
  alt: string;
  type: ImageExtension;
}

export const Image = ({ alt, img, type }: Props) => {
  img = `/images/${img}`;
  const src = `${img}-1x.${type}`;
  const srcSet = `${img}-1x.${type} 1x, ${img}-2x.${type} 2x, ${img}-3x.${type} 3x`;

  return <img className="img-fluid" src={src} srcSet={srcSet} alt={alt} />;
};
