import React from 'react';

export const Contact = () => {
  return (
    <div className="row d-flex justify-content-center position-relative my-5">
      <div id="contact" className="anchor"></div>
      <h2>Book Your Free Call</h2>
      <iframe
        src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ3OscCEXEN-rYzJxs6oEaBu2y4SvOC64dyLXkqL8Ni2KqZVuXtUUoBs6e7ZmnF7Os08L-S5wKWY?gv=true"
        width="100%"
        height="900px"
        frameBorder="0"></iframe>
    </div>
  );
};
