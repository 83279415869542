export enum FeatureTypeKey {
  Bespoke = 'bespoke',
  Search = 'search',
  Notifications = 'notifications',
  Map = 'map',
  Vendors = 'vendors',
  Setlist = 'setlist'
}

export interface Feature {
  key: FeatureTypeKey;
  longTitle: string;
  shortTitle: string;
  footerTitle: string;
  longDescription: string;
  textListOnly?: boolean;
}

export const BespokeFeature: Feature = {
  key: FeatureTypeKey.Bespoke,
  longTitle: 'Infuse Tech Into Your Brand',
  shortTitle: 'Bespoke App Design',
  footerTitle: 'Bespoke App Design',
  longDescription:
    'We bring your brand close to your festival family, enhancing and rekindling the memories and connections you helped visitors establish. Our VIKIN platform has been precisely designed and developed for optimal performance.'
};

export const SearchFeature: Feature = {
  key: FeatureTypeKey.Search,
  longTitle: 'Intelligent Global Search',
  shortTitle: 'Search',
  footerTitle: 'Search',
  longDescription:
    'An app provides so much helpful information, but at times, we all want to easily and quickly search for specific artists, stages or even the closest toilet; we’ve all been there! Our intelligent search gives everyone exactly what they are looking for when needed.'
};

export const NotificationsFeature: Feature = {
  key: FeatureTypeKey.Notifications,
  longTitle: 'Timely Information',
  shortTitle: 'Notifications',
  footerTitle: 'Notifications',
  longDescription:
    'VIKIN is your visitor’s personal assistant - reminding them of their favourite acts, vendor closing times and special discounts!'
};

export const MapFeature: Feature = {
  key: FeatureTypeKey.Map,
  longTitle: 'The Ultimate Festival Map',
  shortTitle: 'Interactive Map',
  footerTitle: 'Interactive Map',
  longDescription:
    "Responsive, fluid, and insightful, helping visitors find their tent, car, or even the next food vendor to grab a bite. With your visitor's location, we can also show relevant content nearby to help you promote your partners and the different activities around your festival."
};

export const VendorFeature: Feature = {
  key: FeatureTypeKey.Vendors,
  longTitle: 'Beautifully Integrate your partners',
  shortTitle: 'Vendors & Sponsors',
  footerTitle: 'Vendors & Sponsors',
  longDescription:
    "Done in the most beautiful way to make your sponsors feel good but not take away from the experience you create. Your partners seamlessly integrate into the app's intelligent and blissful journey whilst increasing revenue."
};

export const SetlistFeature: Feature = {
  key: FeatureTypeKey.Setlist,
  longTitle: 'See when your bands are playing',
  shortTitle: 'Setlist',
  footerTitle: 'Setlist',
  longDescription:
    "Put the setlist into the palm of everyone's hands, not just the current live performances, but also view the previous and upcoming acts, giving your festival family complete visibility to help them decide how to go about their day."
};

export const AllFeatures: Feature[] = [
  BespokeFeature,
  SearchFeature,
  NotificationsFeature,
  MapFeature,
  VendorFeature,
  SetlistFeature
];

const half = Math.ceil(AllFeatures.length / 2);

export const FeaturesLeftHalf: Feature[] = AllFeatures.slice(0, half);
export const FeaturesRightHalf: Feature[] = AllFeatures.slice(half);
