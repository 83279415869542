import React from 'react';

import { Contact } from '../components/Contact';
import { FAQ } from '../components/FAQ/FAQ';
import { Features } from '../components/Features/Features';
import { HomeBanner } from '../components/HomeBanner/HomeBanner';

export const Home = () => (
  <div>
    <HomeBanner />
    <div className="container-lg">
      <Features />
    </div>
    <div className="container-lg">
      <FAQ />
    </div>
    <div className="container-lg">
      <Contact />
    </div>
  </div>
);
