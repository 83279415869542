import React from 'react';

import { Contact } from '../components/Contact';

export const BookCall = () => (
  <div>
    <div className="container-lg">
      <Contact />
    </div>
  </div>
);
