import './Legal.scss';

import React from 'react';

import ReactMarkdown from 'react-markdown';

interface Props {
  content: string;
  title: string;
}

export const Legal = ({ content, title }: Props) => (
  <div className="container-lg">
    <div className="legal-content-container px-3 bg-white">
      <div className="legal-content pt-3 mb-5 text-break">
        <h2>{title}</h2>
        <ReactMarkdown>{content}</ReactMarkdown>
      </div>
    </div>
  </div>
);
