import './CookieBanner.scss';

import React, { useCallback, useEffect } from 'react';

import { ButtonStyleType } from '../../models/button';

import { analytics } from '../../utils/analytics';

import { Button } from '../Button/Button';

const Component = () => {
  useEffect(() => {
    const cookieState = analytics.getCookieState();

    if (cookieState !== null) {
      document.getElementById('cookieBanner')?.classList.add('d-none');
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const disableCookies = useCallback(() => {
    analytics.setCookieState('disable');
    document.getElementById('cookieBanner')?.classList.add('d-none');
  }, []);

  const enableCookies = useCallback(() => {
    analytics.setCookieState('enable');
    document.getElementById('cookieBanner')?.classList.add('d-none');
  }, []);

  return (
    <div id="cookieBanner" className="cookie-banner bg-white position-fixed p-4">
      <p className="h4 text-start pb-3">Cookies 🍪</p>
      <p>
        This website stores data such as cookies to enable essential site functionality and anonymous analytics. You may
        change your settings at any time or accept the default settings. If you deny, one cookie will be stored to hold
        your preference. Read our{' '}
        <a className="p" href="/cookies">
          Cookie Policy
        </a>
        .
      </p>
      <div className="d-flex flex-wrap justify-content-between mt-2">
        <label className="d-flex align-items-center my-2">
          <span onClick={disableCookies} className="p a ms-2">
            Disable
          </span>
        </label>
        <div className="my-2">
          <Button styleType={ButtonStyleType.Secondary} text="Accept" onClick={enableCookies} />
        </div>
      </div>
    </div>
  );
};

export const CookieBanner = React.memo(Component);
