import './Footer.scss';

import React from 'react';

import { IconType } from '../../models/icon';
import { LogoType } from '../../models/logo';

import { AllFeatures } from '../../constants/features';
import { FACEBOOK_URL, INSTAGRAM_URL, LINKEDIN_URL, PESLO_URL } from '../../utils/constants';

import { AppLogo } from '../AppLogo/AppLogo';
import { Icon } from '../Icon/Icon';
import { NavigationItem } from '../NavigationItem/NavigationItem';

export const Footer = () => (
  <footer className="bg-dark-grey">
    <div className="container-xl pb-5 d-flex justify-content-center flex-wrap">
      <div className="d-flex flex-column px-3 footer-col justify-content-between info-container">
        <div className="d-flex info-image">
          <AppLogo type={LogoType.White} />
        </div>
        <div className="d-flex flex-column">
          <small className="mt-4 f-light-grey info-copyright">{`Copyright ${new Date().getFullYear()} © VIKIN`}</small>
          <a className="mt-2 navigation-item" href={PESLO_URL} target="_blank" rel="noreferrer">
            <small className="f-light-grey info-copyright">{`A Peslo Production 🌱`}</small>
          </a>
          <small className="mt-2 f-light-grey info-copyright">{`Company number: 15568001`}</small>

          <div className="d-flex info-image social-links">
            <a href={LINKEDIN_URL} target="_blank" title="Link to VIKIN Linkedin Page" rel="noreferrer">
              <Icon type={IconType.Linkedin} />
            </a>
            <div className="mx-2 border-end b-grey"></div>
            <a href={INSTAGRAM_URL} target="_blank" title="Link to VIKIN Instagram Page" rel="noreferrer">
              <Icon type={IconType.Instagram} />
            </a>
            <div className="mx-2 border-end b-grey"></div>
            <a href={FACEBOOK_URL} target="_blank" title="Link to VIKIN Facebook Page" rel="noreferrer">
              <Icon type={IconType.Facebook} />
            </a>
          </div>
        </div>
      </div>
      <div className="d-flex flex-column px-3 footer-col">
        <p className="h2 text-white text-start">Site Map</p>
        <NavigationItem title="Features" link="#features" external={false} />
        <NavigationItem title="FAQs" link="#faqs" external={false} />
      </div>
      <div className="d-flex flex-column px-3 footer-col">
        <p className="h2 text-white text-start">Features</p>
        {AllFeatures.map(feature => (
          <NavigationItem key={feature.key} title={feature.footerTitle} link={`#${feature.key}`} external={false} />
        ))}
      </div>
      <div className="d-flex flex-column px-3 footer-col">
        <p className="h2 text-white text-start">Legal</p>
        <NavigationItem title="Cookie Policy" link="/cookies" external={false} />
        <NavigationItem title="Privacy Policy" link="/privacy" external={false} />
        <NavigationItem title="App Privacy Policy" link="/app-privacy" external={false} />
        <NavigationItem title="EULA" link="/eula" external={false} />
      </div>
    </div>
  </footer>
);
