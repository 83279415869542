import React from 'react';

import { ButtonStyleType } from '../../models/button';

interface Props {
  text: string;
  link?: string;
  styleType: ButtonStyleType;
  onClick?: () => void;
}

export const Button = ({ link, onClick, styleType, text }: Props) => {
  if (onClick) {
    return (
      <button type="button" className={`caption btn btn-${styleType}`} onClick={onClick}>
        {text}
      </button>
    );
  }

  return (
    <a href={link} className={`caption btn btn-${styleType}`}>
      {text}
    </a>
  );
};
