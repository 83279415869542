import './NavigationIconItem.scss';

import React from 'react';

type Props = {
  title: string;
  subtitle?: string;
  link: string;
};

export const NavigationIconItem = ({ link, subtitle, title }: Props) => {
  return (
    <a
      href={`#${link}`}
      aria-label={`See ${title} feature`}
      className="dd-item d-flex flex-row m-1 p-2 align-items-center">
      <div className="me-3 mx-2">
        <p className="a">{title}</p>
        {subtitle ? <span>{subtitle}</span> : ''}
      </div>
    </a>
  );
};
