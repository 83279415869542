import './HomeBanner.scss';

import React from 'react';

import { DOIcon } from './DOIcon';
import { TBRIcon } from './TBRIcon';

const buildImageDataFor = (imageUrl: string, extension: string) => {
  const backgroundSrcSet = `${imageUrl}-1x.${extension} 1x, ${imageUrl}-2x.${extension} 2x, ${imageUrl}-3x.${extension} 3x`;
  const backgroundSrc = `${imageUrl}.${extension}`;

  return {
    backgroundSrcSet,
    backgroundSrc
  };
};

export const HomeBanner = () => {
  const bannerImageData = buildImageDataFor('/images/banner/header', 'png');
  const appImageData = buildImageDataFor('/images/banner/header-app', 'webp');

  return (
    <div className="px-2 position-relative home-banner">
      <div id="download" className="anchor"></div>
      <img
        aria-label="Hero Banner background image"
        className="hero-background-image"
        srcSet={bannerImageData.backgroundSrcSet}
        src={bannerImageData.backgroundSrc}
      />

      <div className="pt-5 mx-5 px-5 flex-wrap home-banner-container">
        <img className="app-image" srcSet={appImageData.backgroundSrcSet} src={appImageData.backgroundSrc} style={{}} />
        <div className="side-container">
          <div className="text-container">
            <h1 className="f-white title flex-wrap">WORLD CLASS APPS FOR OUTDOOR EVENTS</h1>
          </div>
          <div className="mt-5 d-flex flex-column trusted-by">
            <h5 className="text-white text-start mb-4">Trusted by</h5>
            <div className="d-flex flex-row" style={{ gap: 32 }}>
              <TBRIcon scale={1.5} />
              <DOIcon scale={1.5} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
