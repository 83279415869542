import './index.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
// eslint-disable-next-line import/extensions
import 'bootstrap/dist/js/bootstrap.min.js';

import React, { useEffect } from 'react';

import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { CookieBanner } from './components/CookieBanner/CookieBanner';
import { Footer } from './components/Footer/Footer';
import { Navigation } from './components/Navigation/Navigation';

import { BookCall } from './pages/BookCall';
import { Home } from './pages/Home';
import { Legal } from './pages/Legal';

import { analytics } from './utils/analytics';

import { appPrivacyPolicy } from './constants/app-privacy';
import { cookiePolicy } from './constants/cookies';
import { privacyPolicy } from './constants/privacy';
import { termsOfService } from './constants/terms';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const App = () => {
  useEffect(() => {
    analytics.initialize();
  }, []);

  return (
    <HelmetProvider>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/book-call" element={<BookCall />} />
        <Route path="/cookies" element={<Legal title="Cookie Policy" content={cookiePolicy} />} />
        <Route path="/privacy" element={<Legal title="Privacy Policy" content={privacyPolicy} />} />
        <Route path="/app-privacy" element={<Legal title="App Privacy Policy" content={appPrivacyPolicy} />} />
        <Route path="/eula" element={<Legal title="EULA" content={termsOfService} />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </HelmetProvider>
  );
};

root.render(
  <React.StrictMode>
    <Navigation />
    <Router>
      <div className="position-relative p-0">
        <App />
        <CookieBanner />
      </div>
    </Router>
    <Footer />
  </React.StrictMode>
);
