import './NavigationItem.scss';

import React from 'react';

interface Props {
  title: string;
  link: string;
  external: boolean;
}

export const NavigationItem = ({ external, link, title }: Props) => {
  return (
    <a className="navigation-item" href={link} target={external ? '_blank' : '_self'} rel="noreferrer">
      <span className="a">{title}</span>
    </a>
  );
};
