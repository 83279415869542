import React from 'react';

import { LogoType } from '../../models/logo';
interface Props {
  type: LogoType;
}

function getLogoFile(type: LogoType): string {
  const logoFolder = '/images/logo/';

  switch (type) {
    case LogoType.White:
      return `${logoFolder}vikin-white.svg`;
    case LogoType.Green:
      return `${logoFolder}vikin-green.svg`;
    default:
      throw new Error('An unsupported type has been provided - likely it instead requires an animation file');
  }
}

export const AppLogo = ({ type }: Props) => (
  <a href="/" title="VIKIN Home Page" aria-label="Link to VIKIN Home Page">
    <img
      className="img-fluid"
      src={getLogoFile(type)}
      style={{
        width: type === LogoType.Green ? 150 : 150
      }}
    />
  </a>
);
